import { setConfiguration } from '../configuration/config';
import { configure } from '../configuration/configuration';
import { assignImportedComponents } from '../loadable/assignImportedComponents';
import { loadByChunkname } from '../loadable/loadByChunkName';
import { clearImportedCache, dryRender, getLoadable as loadableResource } from '../loadable/loadable';
import { getMarkedChunks, getMarkedFileNames } from '../loadable/markerMapper';
import { drainHydrateMarks, printDrainHydrateMarks, rehydrateMarks, waitForMarks } from '../loadable/marks';
import { done as whenComponentsReady } from '../loadable/pending';
import { addPreloader } from '../loadable/preloaders';
import { ImportedComponent } from '../ui/Component';
import { ImportedComponent as ComponentLoader } from '../ui/Component';
import imported, { lazy } from '../ui/HOC';
import { ImportedController } from '../ui/ImportedController';
import { LazyBoundary } from '../ui/LazyBoundary';
import { ImportedModule, importedModule } from '../ui/Module';
import { ImportedStream } from '../ui/context';
import { useImported, useLazy, useLoadable } from '../ui/useImported';
import { remapImports } from '../utils/helpers';
import { useIsClientPhase } from '../utils/useClientPhase';
export { printDrainHydrateMarks, drainHydrateMarks, rehydrateMarks, waitForMarks, whenComponentsReady, dryRender, assignImportedComponents, loadByChunkname, ComponentLoader, ImportedComponent, ImportedModule, loadableResource, ImportedStream, setConfiguration, imported, importedModule, lazy, LazyBoundary, ImportedController, useIsClientPhase, remapImports, useLoadable, useImported, useLazy, addPreloader, getMarkedChunks, getMarkedFileNames, clearImportedCache, configure, };
export default imported;
